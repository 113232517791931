(function($) {
  $(document).ready(function() {
    if (!Modernizr.svg) {
      var imgs = document.getElementsByTagName('img');
      var svgExtension = /.*\.svg$/
      var l = imgs.length; 
      for(var i = 0; i < l; i++) {
        if(imgs[i].src.match(svgExtension)) {
          imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
          imgs[i].src = imgs[i].src.replace(/\/svg\//,'/img/');
        }
      }
    }
  });
})(jQuery);